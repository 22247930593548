html, body, #root {
  height: 100%;
  display: flex;
  width: 100%; }
body {
  background-color: black;
  color: white; }
.app {
  display: flex;
  height: 100%;
  flex-direction: column;
  flex-grow: 1; }

.extra-sidebar {
  overflow: hidden;
  display: flex;
  .extra-sidebar-button {
    height: 100%;
    width: 12px;
    padding: 1px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color .2s linear;
    background-color: #398D73;
    border: none;
    outline: none;
    &:hover {
      background-color: #2593FC; }
    &:active {
      outline: none;
      border: none; } } }
